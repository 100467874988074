import validation from '@/mixins/validation'
import moment from 'moment'

export default {
  mixin: [validation],
  name: 'user-admins-roles-item',
  props: {
    item: {
      type: Object
    },
    selected: {}
  },
  data () {
    return {
      payload: {
        role_id: null,
        available_to: null,
        type: ''
      }
    }
  },
  watch: {
    payload: {
      handler () {
        this.payload.type = this.payload.role_id ? 'add' : 'delete'
        this.payload.available_to = this.payload.available_to ? moment(this.payload.available_to).format('YYYY-MM-DD HH:mm:ss') : ''
        this.$emit('update', this.payload)
      },
      deep: true
    }
  },
  created () {
    if (this.selected) {
      Object.assign(this.payload, this.selected)
    }
  }
}
