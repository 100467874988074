import { mapActions, mapGetters } from 'vuex'
import Item from '../../components/roles-item/index.vue'

export default {
  name: 'user-admin-roles',
  data () {
    return {
      selectedRoles: []
    }
  },
  components: {
    Item
  },
  computed: {
    ...mapGetters({
      roles: 'userRoles/list',
      isRolesLoading: 'userRoles/isListLoading',
      currentUser: 'userAdmins/currentStudent',
      isLoading: 'userAdmins/isLoading'
    })
  },
  methods: {
    ...mapActions({
      fetchRoles: 'userRoles/GET_LIST',
      update: 'userAdmins/UPDATE_ROLES'
    }),
    setRole (item) {
      return this.currentUser.roles.data.find(e => e.role_id === item.id)
    },
    updateItem (item) {
      const index = this.selectedRoles.findIndex(e => e.role_id === item.role_id)
      if (item.type === 'add') {
        if (index >= 0) {
          this.$set(this.selectedRoles, index, item)
        } else {
          this.selectedRoles.push(item)
        }
      } else {
        this.$delete(this.selectedRoles, index)
      }
    },
    createRequestPayload () {
      const formData = new FormData()
      this.selectedRoles.forEach((e, index) => {
        Object.entries(e).forEach(t => {
          formData.append(`roles[${index}][${t[0] === 'role_id' ? 'id' : t[0]}]`, t[1])
        })
      })
      formData.append('userId', this.$route.params.id)
      formData.append('_method', 'patch')

      return formData
    },
    async submit () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.update(this.createRequestPayload()).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    }
  },
  created () {
    this.fetchRoles()
  }
}
